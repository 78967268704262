import {
  matchDeferred,
  matchDeferredTuple,
  taggedUnion,
  Variants,
} from "@pomebile/shared/tagged-union"
import { UniqueLinkInfo } from "./api/webRoutes"

interface Screens {
  FetchingUniqueLinkInfo: void
  VerifyIdentity: {
    senderName: string
    transferAmount: number
    transferExpiration: number
    isVeriffRequired: boolean
  }
  ConfirmDeposit: {
    recipientBankPayer: string
    recipientBankLast4: string
  }
  Success: {
    recipientBankPayer: string
    recipientBankLast4: string
  }
  InvalidLink: void
  TooManyAttempts: void
  TimeLimitError: void
  GeneralError: void
}

export const AppScreen = taggedUnion<Screens>()
export type AppScreen = Variants<Screens>

interface AppStates {
  FetchingInfo: void
  VerifyingIdentity: {
    uniqueLinkInfo: UniqueLinkInfo
  }
  ConfirmingDeposit: {
    recipientBankPayer: string
    recipientBankLast4: string
  }
  Complete: {
    recipientBankPayer: string
    recipientBankLast4: string
  }
  TimeoutError: void
  InvalidLinkError: void
  TooManyAttemptsError: void
  GeneralError: void
}

interface AppEvents {
  FetchedUniqueLinkInfo: {
    uniqueLinkInfo: UniqueLinkInfo
  }
  CompletedIdentityVerification: void
  SkippedIdentityVerification: void
  ConfirmedDepositAmount: void
  UsedInvalidLink: void
  RequestTimedOut: void
  RequiredSupport: void
  ExceededAttemptsLimit: void
  EncounteredGeneralError: { errorType: string }
}

export const AppState = taggedUnion<AppStates>()
export type AppState = Variants<AppStates>

export const AppEvent = taggedUnion<AppEvents>()
export type AppEvent = Variants<AppEvents>

export const calculateRecipientVerificationScreen: (state: AppState) => AppScreen = matchDeferred(
  AppState,
  {
    FetchingInfo: () => AppScreen.FetchingUniqueLinkInfo(),
    VerifyingIdentity: ({ uniqueLinkInfo }) =>
      AppScreen.VerifyIdentity({
        senderName: uniqueLinkInfo.senderName,
        transferAmount: uniqueLinkInfo.transferAmount,
        transferExpiration: uniqueLinkInfo.linkExpirationTs,
        isVeriffRequired: uniqueLinkInfo.isVeriffRequired,
      }),
    InvalidLinkError: () => AppScreen.InvalidLink(),
    TooManyAttemptsError: () => AppScreen.TooManyAttempts(),
    Complete: ({ recipientBankLast4, recipientBankPayer }) =>
      AppScreen.Success({ recipientBankLast4, recipientBankPayer }),
    ConfirmingDeposit: ({ recipientBankLast4, recipientBankPayer }) =>
      AppScreen.ConfirmDeposit({ recipientBankLast4, recipientBankPayer }),
    TimeoutError: () => AppScreen.TimeLimitError(),
    GeneralError: () => AppScreen.GeneralError(),
  },
)

const {
  Complete,
  ConfirmingDeposit,
  VerifyingIdentity,
  InvalidLinkError,
  TooManyAttemptsError,
  TimeoutError,
  GeneralError,
} = AppState

export const updateRecipientVerificationAppState = matchDeferredTuple(AppState, AppEvent, {
  FetchingInfo: {
    FetchedUniqueLinkInfo: (_, { uniqueLinkInfo }) => VerifyingIdentity({ uniqueLinkInfo }),
    UsedInvalidLink: () => InvalidLinkError(),
    EncounteredGeneralError: () => GeneralError(),
  },

  VerifyingIdentity: {
    CompletedIdentityVerification: ({
      uniqueLinkInfo: { recipientBankLast4, recipientBankPayer },
    }) =>
      ConfirmingDeposit({
        recipientBankLast4,
        recipientBankPayer,
      }),
    RequestTimedOut: () => TimeoutError(),
    RequiredSupport: () => GeneralError(),
    EncounteredGeneralError: () => GeneralError(),
  },

  ConfirmingDeposit: {
    ConfirmedDepositAmount: ({ recipientBankLast4, recipientBankPayer }) =>
      Complete({ recipientBankLast4, recipientBankPayer }),
    ExceededAttemptsLimit: () => TooManyAttemptsError(),
    EncounteredGeneralError: () => GeneralError(),
  },

  default: (prev) => prev,
})
