/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * This is the backend API
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const PlaidCheckSessionStatus = {
  Created: "CREATED",
  Success: "SUCCESS",
  Exited: "EXITED",
  NotExist: "NOT_EXIST",
} as const
export type PlaidCheckSessionStatus =
  (typeof PlaidCheckSessionStatus)[keyof typeof PlaidCheckSessionStatus]

export function PlaidCheckSessionStatusToJSON(value?: PlaidCheckSessionStatus | null): any {
  return value as any
}
