import { Secure, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { sprinkles } from "@pomebile/primitives-web"

export type TimeLimitErrorScreenProps = {
  email?: string
}

export function TimeLimitErrorScreen({ email }: TimeLimitErrorScreenProps) {
  return (
    <ScreenFrame>
      <VStack height="full" alignItems="center" justifyContent="center">
        <Txt variant="headline2" as="h1" textAlign="center">
          Sorry, this is taking longer than expected.
        </Txt>
        {/* Todo: for when we have no email, we need a more generic error */}
        {email && (
          <Secure>
            <Txt as="p" variant="body1" textAlign="center">
              We will send an email to{" "}
              <span className={sprinkles({ wordBreak: "break-all" })}>{email}</span> when we've
              successfully processed your info.
            </Txt>
          </Secure>
        )}
      </VStack>
    </ScreenFrame>
  )
}
