import { useReducer, useCallback, useEffect } from "react"
import { useConstant } from "@pomebile/shared/tagged-union"
import { appStyle, emptyHeaderStyle } from "./AppShell.css"
import { AuthContext, AuthData } from "./api/authContext"
import { AppApiContext, authenticateIntercomUsingGet, generateToken } from "./api/webRoutes"
import { DevTools } from "./components/DevTools"
import { ScreenHeader } from "./components/ScreenHeader/ScreenHeader"
import { ScreenTransitionShell } from "./screens/ScreenTransitionShell"
import { useIntercomMachine, IntercomEvent } from "./utils/intercom"
import { LoggingContext, LoggingProvider, devLogging } from "./utils/logging"
import { segmentAnalyticsLogger } from "./utils/segment"
import { sentryErrorLogger } from "./utils/sentry"
import { toTitleCase } from "./utils/string"
import { getLogRocketUrlAsync, identifyLogRocketUser } from "./utils/logrocket"
import { ErrorData } from "./api/errorContext"

type ExtraLoggingContext = {
  experiments: {
    [key: string]: boolean | number | string
  }
}

export const createLoggingContext = (extra?: ExtraLoggingContext): LoggingContext => {
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const withExtra = (data: object | undefined) => {
    return {
      searchParams,
      environment: import.meta.env,
      ...extra,
      ...data,
    }
  }

  return {
    logUser: (userId: string, userTraits?) => {
      devLogging.logUser(userId)
      sentryErrorLogger.logUser(userId)
      segmentAnalyticsLogger.logUser(userId, userTraits)
    },
    logError: (err: unknown) => {
      devLogging.logError(err)
      sentryErrorLogger.logError(err)
      segmentAnalyticsLogger.logError(err)
    },
    logScreen: (screenName, data) => {
      devLogging.logScreen(screenName, withExtra(data))
      sentryErrorLogger.logScreen(screenName, withExtra(data))
      segmentAnalyticsLogger.logScreen(screenName, withExtra(data))
    },
    logEvent: (eventName, data) => {
      devLogging.logEvent(eventName, withExtra(data))
      sentryErrorLogger.logEvent(eventName, withExtra(data))
      segmentAnalyticsLogger.logEvent(eventName, withExtra(data))
    },
    logFormEvent: (formName, event) => {
      devLogging.logFormEvent(formName, event)
      sentryErrorLogger.logFormEvent(formName, event)
      segmentAnalyticsLogger.logFormEvent(formName, event)
    },
    logErrorEvent: (eventName, data) => {
      devLogging.logErrorEvent(eventName, data)
      sentryErrorLogger.logErrorEvent(eventName, data)
      segmentAnalyticsLogger.logErrorEvent(eventName, data)
    },
  }
}

export function createAuthContext(apiCx: AppApiContext): AuthContext {
  return {
    refreshPromise: undefined,
    doRefreshToken: async (refreshToken) => {
      const tokens = await generateToken({ refreshToken }, apiCx)

      sessionStorage.setItem("refreshToken", tokens.refreshToken)
      sessionStorage.setItem("token", tokens.token)

      return tokens
    },
    getCachedAuthTokens: () => {
      const refreshToken = sessionStorage.getItem("refreshToken")
      const token = sessionStorage.getItem("token")

      if (refreshToken && token) {
        return { refreshToken, token }
      }

      return undefined
    },
  }
}

export type ShellState<State, Screen> = {
  state: State
  screens: Screen[]
}

export type Progression = "apply" | "accept-plan" | "download-app" | "none"

export type InitialData<State, Screen> = {
  initialState: ShellState<State, Screen>
  authCx: AuthContext
  apiCx: AppApiContext
  logging: LoggingContext
}

function update<State, Ev, Screen extends { tag: string }>(
  prev: ShellState<State, Screen>,
  ev: Ev,
  calculateScreen: (state: State) => Screen,
  updateState: (prev: State, ev: Ev) => State,
): ShellState<State, Screen> {
  const appState = updateState(prev.state, ev)

  if (appState === prev.state) {
    // no updates happened
    return prev
  }

  const curScreen = calculateScreen(appState)

  if (curScreen.tag !== prev.screens[prev.screens.length - 1].tag) {
    return { state: appState, screens: prev.screens.concat(curScreen) }
  }

  return { state: appState, screens: prev.screens }
}

export function AppShell<
  State extends { tag: string },
  Ev extends { tag: string },
  Screen extends { tag: string },
>({
  isDevToolsEnabled,
  renderScreen,
  updateState,
  getAuth,
  getError,
  init,
  calculateScreen,
  calculateProgression,
  sessionPersistenceEnabled,
}: {
  isDevToolsEnabled: boolean
  init: () => InitialData<State, Screen>
  getAuth: (state: State) => AuthData | undefined
  getError: (ev: Ev) => ErrorData | undefined
  updateState: (prev: State, ev: Ev) => State
  calculateScreen: (state: State) => Screen
  renderScreen: (
    screen: Screen,
    send: (ev: Ev) => void,
    apiCx: AppApiContext,
    auth: AuthContext,
  ) => JSX.Element
  calculateProgression: (screen: Screen) => Progression
  sessionPersistenceEnabled?: boolean
}) {
  const { initialState, apiCx, authCx, logging } = useConstant(init)
  const reducer = (prev: ShellState<State, Screen>, ev: Ev): ShellState<State, Screen> => {
    return update(prev, ev, calculateScreen, updateState)
  }

  const [{ screens, state }, send] = useReducer(reducer, initialState)

  const trackAndSend = useCallback(
    (ev: Ev) => {
      // Avoiding sending data until we build out PII filtering logic.
      const error = getError(ev)
      if (error) {
        logging.logErrorEvent(toTitleCase(ev.tag), { errorType: error.errorType })
      } else {
        logging.logEvent(toTitleCase(ev.tag))
      }
      send(ev)
    },
    [send], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const auth = getAuth(state)
  const userIdent = auth?.userIdent

  useEffect(() => {
    if (sessionPersistenceEnabled && auth) {
      sessionStorage.setItem("refreshToken", auth.tokens.refreshToken)
      sessionStorage.setItem("token", auth.tokens.token)
    }
  }, [auth, sessionPersistenceEnabled])

  const getIntercomAuthHash = (auth: AuthData) => {
    return authenticateIntercomUsingGet(auth.userIdent, apiCx, auth, authCx)
  }

  const [intercomState, sendToIntercomRaw] = useIntercomMachine(getIntercomAuthHash)

  // Purely analytics for our own analysis.
  // Intercom will get its own information through Segment destination
  const sendToIntercom = (ev: IntercomEvent) => {
    logging.logEvent(`Intercom ${ev.tag}`)
    sendToIntercomRaw(ev)
  }
  useEffect(() => {
    logging.logEvent(`Intercom ${intercomState.tag}`)
  }, [intercomState.tag]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userIdent) {
      // Avoiding sending data until we build out PII filtering logic.
      sendToIntercom({ tag: "UserLoggedIn", data: auth })
      identifyLogRocketUser({ id: userIdent, email: auth.email })

      getLogRocketUrlAsync()
        .then((logRocketUrl) => {
          // Attach the LogRocket session URL to the user as a trait
          logging.logUser(userIdent, { logRocketUrl })
        })
        .catch((err) => {
          // This promise should not fail, but just in case...
          logging.logUser(userIdent, { logRocketUrl: "" })
          logging.logError(err)
        })
    }
  }, [userIdent]) // eslint-disable-line react-hooks/exhaustive-deps

  function checkHideIntercom(lastScreenTag: string): void {
    if (lastScreenTag === ("Rejected" || "ManualReview")) {
      sendToIntercom({
        tag: "Hide",
        data: undefined,
      })
    }
  }

  const lastScreenTag = screens[screens.length - 1].tag
  useEffect(() => {
    // this is the place that detects when screen changes
    const formattedTag = toTitleCase(lastScreenTag)
    const titleCaseLastScreenTag = formattedTag
    //
    // 1. Analytics
    // Avoiding sending more data until we build out PII filtering logic.
    logging.logScreen(titleCaseLastScreenTag, { state: state.tag })
    //
    // 2. Scroll to the top when screen changes
    window.scrollTo({ top: 0, behavior: "smooth" })
    // 3. Determine whether to show/hide intercom and trigger reload
    checkHideIntercom(formattedTag)
  }, [lastScreenTag, state.tag]) // eslint-disable-line react-hooks/exhaustive-deps

  const screensCount = screens.length
  return (
    <LoggingProvider logger={logging}>
      <div className={appStyle}>
        {isDevToolsEnabled && <DevTools state={{ state, apiCx }} />}
        {/* Empty absolutely positioned whitespace to keep a consistent horizontal
        line across the scd wcreen in header. Does not need to be animated. Also
        enables us to make minimal easter egg progress bar in header. */}
        <div className={emptyHeaderStyle} />
        {screens.map((screen, index) => (
          <ScreenTransitionShell
            status={
              screensCount === 1 ? "initial" : screensCount - 1 === index ? "current" : "past"
            }
            key={screen.tag}
          >
            <ScreenHeader
              intercomState={intercomState}
              activeSection={calculateProgression(screen)}
            />
            {renderScreen(screen, trackAndSend, apiCx, authCx)}
          </ScreenTransitionShell>
        ))}
      </div>
    </LoggingProvider>
  )
}
