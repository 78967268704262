import { match } from "@pomebile/shared/tagged-union"
import {
  AppEvent,
  AppScreen,
  AppState,
  calculateRecipientVerificationScreen,
} from "./recipientVerificationModel"
import {
  AppApiContext,
  generateRecipientVerificationVeriffSession,
  getRecipientVerificationActiveVeriffSession,
  queryRecipientVerificationVeriffDecision,
  queryUniqueLinkInfo,
  startRecipientVerificationVeriffSdk,
  submitMicroDepositAmount,
} from "./api/webRoutes"
import { GeneralErrorScreen } from "./screens/GeneralError"
import { RecipientVerificationIntro } from "./screens/RecipientVerificationIntro"
import {
  createAuthContext,
  createLoggingContext,
  InitialData,
  ShellState,
} from "./sharedShellLogic"
import { DEBUG_RESTORE_KEY, DEBUG_STORAGE_KEY } from "./components/DevTools"
import { anonymousIdPromise } from "./utils/segment"
import { RecipientVerificationInvalidLink } from "./screens/RecipientVerificationInvalidLink"
import { RecipientVerificationTooManyAttempts } from "./screens/RecipientVerificationTooManyAttempts"
import { RecipientVerificationSuccess } from "./screens/RecipientVerificationSuccess"
import { RecipientVerificationConfirmDeposit } from "./screens/RecipientVerificationConfirmDeposit"
import { FetchingUniqueLinkInfo } from "./screens/FetchingUniqueLinkInfo"
import { TimeLimitErrorScreen } from "./screens/TimeLimitError"

export type Progression = "none"

export const calculateProgression = (): Progression => "none"

const searchParams = new URLSearchParams(document.location.search)
const uniqueLinkId = searchParams.get("uniqueId") ?? undefined

export const renderScreen = (screen: AppScreen, send: (ev: AppEvent) => void): JSX.Element =>
  match(
    screen,
    {
      FetchingUniqueLinkInfo: () =>
        uniqueLinkId ? (
          <FetchingUniqueLinkInfo
            api={{
              fetchUniqueLinkInfo: () => queryUniqueLinkInfo(uniqueLinkId),
            }}
            onDone={(resp) => {
              switch (resp.tag) {
                case "success":
                  send(AppEvent.FetchedUniqueLinkInfo({ uniqueLinkInfo: resp.info }))
                  break

                case "error":
                  send(AppEvent.UsedInvalidLink())
                  break

                case "unexpectedError":
                  send(
                    AppEvent.EncounteredGeneralError({
                      errorType: "recipientVerificationUniqueLinkUnexpectedError",
                    }),
                  )
                  break
              }
            }}
          />
        ) : (
          <RecipientVerificationInvalidLink />
        ),
      VerifyIdentity: ({ senderName, transferAmount, transferExpiration, isVeriffRequired }) => (
        <RecipientVerificationIntro
          recipientName={senderName}
          transferAmount={transferAmount}
          transferExpiration={transferExpiration}
          isVeriffRequired={isVeriffRequired}
          api={{
            generateVeriffSession: () => generateRecipientVerificationVeriffSession(),
            getActiveVeriffSession: () => getRecipientVerificationActiveVeriffSession(),
            startVeriffSdk: () => startRecipientVerificationVeriffSdk(),
            queryDecision: () => queryRecipientVerificationVeriffDecision(),
          }}
          onDone={(outcome) => {
            const { tag } = outcome

            switch (tag) {
              case "approved":
              case "manualReview":
              case "rejected":
                send(AppEvent.CompletedIdentityVerification())
                break

              case "requires_support":
                send(AppEvent.RequiredSupport())
                break

              case "pending":
                send(AppEvent.RequestTimedOut())
                break

              case "requires_resubmission":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "recipientVerificationVeriffRequiredResubmission",
                  }),
                )
                break

              case "veriffNotRequired":
                send(AppEvent.SkippedIdentityVerification())
                break
            }
          }}
        />
      ),
      ConfirmDeposit: ({ recipientBankLast4, recipientBankPayer }) => (
        <RecipientVerificationConfirmDeposit
          bankPayer={recipientBankPayer}
          last4={recipientBankLast4}
          api={{
            verifyDepositAmount: (depositAmount) => submitMicroDepositAmount(depositAmount),
          }}
          onDone={(outcome) => {
            const { tag } = outcome

            switch (tag) {
              case "success":
                send(AppEvent.ConfirmedDepositAmount())
                break

              case "tooManyAttempts":
                send(AppEvent.ExceededAttemptsLimit())
                break

              case "unexpectedError":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "recipientVerificationUnexpectedError",
                  }),
                )
                break
            }
          }}
        />
      ),
      Success: ({ recipientBankLast4, recipientBankPayer }) => (
        <RecipientVerificationSuccess bankPayer={recipientBankPayer} last4={recipientBankLast4} />
      ),
      InvalidLink: () => <RecipientVerificationInvalidLink />,
      TooManyAttempts: () => <RecipientVerificationTooManyAttempts />,
      TimeLimitError: () => <TimeLimitErrorScreen />,
      GeneralError: () => <GeneralErrorScreen />,
    },
    // There shouldn't ever be a case here, but just in case...
    (_) => <GeneralErrorScreen />,
  )

export function initRecipientVerification(
  apiCx: AppApiContext,
  isDevToolsEnabled: boolean,
): InitialData<AppState, AppScreen> {
  const logger = createLoggingContext()

  if (isDevToolsEnabled) {
    const restore = localStorage.getItem(DEBUG_RESTORE_KEY) === "yes"
    localStorage.removeItem(DEBUG_RESTORE_KEY)

    if (restore) {
      const serialized = localStorage.getItem(DEBUG_STORAGE_KEY)
      if (serialized) {
        const { state, apiCx }: { state: AppState; apiCx: AppApiContext } = JSON.parse(serialized)

        return {
          initialState: { state, screens: [calculateRecipientVerificationScreen(state)] },
          apiCx,
          authCx: createAuthContext(apiCx),
          logging: logger,
        }
      }
    }
  }

  const initialState: ShellState<AppState, AppScreen> = {
    state: uniqueLinkId ? AppState.FetchingInfo() : AppState.InvalidLinkError(),
    screens: [uniqueLinkId ? AppScreen.FetchingUniqueLinkInfo() : AppScreen.InvalidLink()],
  }

  anonymousIdPromise?.then((id) => {
    apiCx.anonymousId = id
  })

  return {
    initialState,
    apiCx,
    authCx: createAuthContext(apiCx),
    logging: logger,
  }
}
